import { useMatches } from "@remix-run/react";
import type { MainSchema, SettingsSchema } from "./schemas";
import type { ProductJson } from "./jsonSchemas";

export interface RootSchema {
  main: MainSchema;
  products: ProductJson[];
  settings: SettingsSchema;
  userId?: string | undefined;
}

export function useMainData(): MainSchema {
  const match = useMatches();
  return (match[0].data as RootSchema).main;
}

export function useSettingsData(): SettingsSchema {
  const match = useMatches();
  return (match[0].data as RootSchema).settings;
}

export function useProducts(): ProductJson[] {
  const match = useMatches();
  return (match[0].data as RootSchema).products.filter(
    (p) => p.visibility !== "gone"
  );
}

export function useUserId(): string | undefined {
  const match = useMatches();
  return (match[0].data as RootSchema).userId;
}

export function useTextColorForSecondaryBg(): string {
  const main = useMainData();
  const hexToRgb = (hex: string) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      ?.map((x) => parseInt(x, 16));

  const rgb = hexToRgb(main.secondaryColor);
  if (!rgb) {
    return "black";
  }

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  );
  const textColour = brightness > 125 ? "black" : "white";
  return textColour;
}

export function useTextColorForPrimaryBg(): string {
  const main = useMainData();
  const hexToRgb = (hex: string) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      ?.map((x) => parseInt(x, 16));

  const rgb = hexToRgb(main.primaryColor);
  if (!rgb) {
    return "black";
  }

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  );
  const textColour = brightness > 125 ? "black" : "white";
  return textColour;
}
